import { toast } from 'react-toastify'
import { all, call, put, select } from 'redux-saga/effects'
import Swal from 'sweetalert2'

import { StaticS3Client } from '../../lib/s3'
import { fetchBase64 } from '../../lib/images'
import { EXCLUSIVE_CONTENTS, StaticScopeMetadata } from '../../lib/scope'

import { formatList, getIdToken, hashObject, resolveScope } from '../../utils'
import { SCHEMA_SCORE_PLD, validateForm } from '../../utils/form'

import { BUCKET_NAME, REPORT_BASE_URL } from '../../config/env'

import API from '../../services/api'

import { selectMinData } from '../selectors/dadosBasicos'
import {
  setDefaultStreetViewImageSulBrasil,
  setFormularioSalvoSulBrasil
} from '../store/sulbrasil'

const mapScore = score => {
  return score.reduce((obj, item) => {
    obj[item.id] = item.value
    return obj
  }, {})
}

export function * generateReportSulBrasil (action) {
  const toastId = 'sulbrasil-gerando-relatorio'

  const dataScorePld = yield select(
    state => state.sulbrasil.formulario.scorePld.form
  )
  const [
    empresasSocios,
    targetDocument,
    targetName,
    globalStartTime,
    targetSerasa,
    executionId
  ] = yield select(state => [
    state.globalStep.empresasSocios,
    state.globalStep.targetDocument,
    state.globalStep.targetName,
    state.globalStep.metadata.startedAt,
    state.globalStep.targetSerasa,
    state.globalStep.metadata.executionArn
  ])

  const mappedScorePld = mapScore(dataScorePld)

  const isValidPld = yield call(() =>
    validateForm({ schema: SCHEMA_SCORE_PLD, data: mappedScorePld })
      .then(() => true)
      .catch(() => false)
  )

  let empresasSelecionadas = []
  const providers = StaticScopeMetadata.getAvailableProviders()
  const hasSerasaPermission = providers.includes(
    EXCLUSIVE_CONTENTS.PROVIDERS.SERASA
  )

  if (hasSerasaPermission) {
    const { isConfirmed, empresasSelecionadas: tmpEmpresas } = yield call(
      openSerasaEmpresasRelacionadas,
      { empresasSocios, targetDocument }
    )

    if (!isConfirmed) {
      return
    }

    empresasSelecionadas = tmpEmpresas
  }

  const isCPf = targetDocument.length === 11
  let consultType = null
  if (isCPf) {
    consultType = yield call(openCheckPfRural, {
      empresasSocios,
      targetDocument
    })

    console.log('consultType', consultType)

    if (!consultType) {
      return
    }
  }

  if (!isValidPld) {
    const scores = [{ name: 'PLD', isValid: isValidPld }]
      .filter(score => !score.isValid)
      .map(score => score.name)
    const title =
      'Você não preencheu todos os campos do(s) score(s) ' +
      formatList(scores, null, 'long', 'conjunction')

    const { isConfirmed } = yield call(openFormAlert, {
      title,
      text: 'Deseja continuar mesmo assim?'
    })

    if (!isConfirmed) {
      return
    }
  }

  const toastIdSerasa = 'sulbrasil-consultando-serasa'
  let serasaS3Path
  try {
    if (empresasSelecionadas.length > 0) {
      if (targetSerasa) {
        empresasSelecionadas.push({
          cnpj: targetDocument,
          razaoSocial: targetName,
          personType: 'pj'
        })
      }

      toast('Consultando Serasa...', {
        toastId: toastIdSerasa
      })
      const output = yield call(API.serasa.consultar, {
        targetDocument,
        globalStartTime,
        empresasSelecionadas
      })
      serasaS3Path = output.s3Path
    }
  } catch (err) {
    console.error(err)
    toast.error('Erro ao consultar serasa', {
      position: toast.POSITION.BOTTOM_RIGHT
    })

    return
  } finally {
    toast.dismiss(toastIdSerasa)
  }

  try {
    const accessToken = yield call(getIdToken)
    const s3Instance = yield call(StaticS3Client.getInstance, { accessToken })
    const aggregateFileReport = yield call(getAggregatedFileReport, {
      s3Instance,
      targetDocument
    })

    if (aggregateFileReport) {
      const { isConfirmed: isConfirmed1 } = yield call(openFormAlert, {
        title: 'Deseja apagar os dados do relatório atual?',
        text:
          'Ao gerar o comentário você pode apagar as informações já preenchidas do relatório atual ou manter o que já está preenchido. Caso o relatório já tenha sido arquivado a versão arquivada não será alterada ou apagada.',
        focusDeny: true
      })

      if (isConfirmed1) {
        const { isConfirmed, value } = yield call(openFormAlertWithInput, {
          title: 'Confirme a ação para continuar',
          text:
            'Digite "apagar" no campo abaixo para confirmar a ação. Essa ação não poderá ser desfeita.',
          input: 'text',
          inputLabel: 'Digite "apagar" para confirmar',
          confirmButtonText: 'Apagar',
          denyButtonText: 'Cancelar',
          inputValidator: value => {
            if (!value || (value || '').toLowerCase() !== 'apagar') {
              return 'Você deve digitar "apagar" para confirmar a ação'
            }
          },
          focusDeny: true
        })

        if (!isConfirmed) {
          return
        }

        toast('Gerando relatório...', {
          toastId
        })

        if (isConfirmed && (value || '').toLowerCase() === 'apagar') {
          const field = getCommentField()
          const newField = getBackupCommentField()
          yield call(API.comments.move, {
            document: targetDocument,
            field,
            newField
          })
        }
      } else {
        toast('Gerando relatório...', {
          toastId
        })
      }
    } else {
      toast('Gerando relatório...', {
        toastId
      })
    }

    const metadata = yield select(state => state.globalStep.metadata)
    if (!metadata.finishedAt) {
      toast.error('Aguarde o término do carregamento')
      return
    }

    const forms = yield select(state => state.sulbrasil.formulario)

    const formsOutputData = yield call(writeForm, {
      forms: {
        ...forms,
        consultType,
        imagens: {
          ...forms.imagens,
          defaultStreetView: undefined,
          streetView:
            forms.imagens.streetView || forms.imagens.defaultStreetView || []
        }
      },
      s3Instance,
      targetDocument
    })
    const aggregatedContent = yield call(readAggregatedFile, {
      s3Instance,
      targetDocument
    })

    const filteredAggregatedPath = Object.fromEntries(
      Object.entries(aggregatedContent).filter(
        ([_, { key }]) => typeof key === 'string'
      )
    )

    const mappedPaths = Object.fromEntries(
      Object.entries(filteredAggregatedPath).map(
        ([keyObj, { key, versionId }]) => {
          return [keyObj, { key: `${key}_relatorio.json`, versionId }]
        }
      )
    )

    mappedPaths.forms = {
      key: formsOutputData.key,
      version_id: formsOutputData.versionId
    }

    if (serasaS3Path) {
      mappedPaths.serasa = {
        key: serasaS3Path
      }
    }

    yield call(copyAllFiles, {
      s3Instance,
      originalPaths: filteredAggregatedPath,
      targetPaths: mappedPaths
    })

    yield call(writeAggregatedFile, {
      s3Instance,
      targetDocument,
      data: mappedPaths
    })

    try {
      yield call(API.rating.update, {
        document: targetDocument,
        executionDate: globalStartTime
      })
    } catch (err) {
      console.error(err)
    }

    yield call(API.rootStep.updateStatus, {
      executionId,
      status: 'online-report'
    })

    yield put(setFormularioSalvoSulBrasil(true))
    toast.dismiss(toastId)

    yield call(openPopup, { targetDocument })
  } catch (err) {
    console.error(err)
    toast.error('Erro ao gerar relatório', {
      position: toast.POSITION.BOTTOM_RIGHT
    })
  } finally {
    toast.dismiss(toastId)
  }
}

const openSerasaEmpresasRelacionadas = async ({
  empresasSocios,
  targetDocument
}) => {
  const otherEmpresaSocios = empresasSocios.filter(
    empresa => empresa.cnpj !== targetDocument
  )

  const { isConfirmed, value } = await Swal.fire({
    title:
      'Selecione as empresas do grupo econômico que deseja consultar na Serasa',
    html: `
    <div style='max-height: 40vh; overflow-y: auto;'>
      ${otherEmpresaSocios
        .map(empresa => {
          return `<div style='display: grid; grid-auto-columns: 1fr; grid-template-columns: 0.1fr 0.9fr;'>
            <div>
              <input type="checkbox" id="empresa${empresa.cnpj}" name="${empresa.cnpj}" value="${empresa.cnpj}">
            </div>
            <label for="${empresa.cnpj}">${empresa.razaoSocial}</label><br>
          </div>
          `
        })
        .join('')}
    </div>
    `,
    showConfirmButton: true,
    showDenyButton: false,
    allowOutsideClick: false,
    showCloseButton: true,
    icon: 'question',
    confirmButtonText: 'Continuar',
    preConfirm: () => {
      return otherEmpresaSocios
        .map(empresa => {
          const checked = Swal.getPopup().querySelector(
            `#empresa${empresa.cnpj}`
          ).checked
          return { ...empresa, checked, personType: 'pj' }
        })
        .filter(empresa => empresa.checked)
    }
  })

  return { isConfirmed, empresasSelecionadas: value }
}

const openCheckPfRural = async () => {
  const { isConfirmed, isDenied } = await Swal.fire({
    title: 'Cadastro rural?',
    html: 'O documento informado é um cadastro rural?',
    showConfirmButton: true,
    showDenyButton: true,
    allowOutsideClick: false,
    showCloseButton: true,
    showCancelButton: true,
    icon: 'question',
    confirmButtonText: 'Sim',
    denyButtonText: 'Não',
    cancelButtonText: 'Cancelar'
  })

  if (isConfirmed) {
    return 'rural'
  }
  if (isDenied) {
    return 'not-rural'
  }

  return null
}

const openFormAlert = async ({ title, text, focusDeny }) => {
  const { isConfirmed } = await Swal.fire({
    title,
    text,
    showConfirmButton: true,
    showDenyButton: true,
    allowOutsideClick: false,
    showCloseButton: false,
    icon: 'warning',
    confirmButtonText: 'Sim',
    denyButtonText: 'Não',
    focusDeny
  })

  return { isConfirmed }
}

const openFormAlertWithInput = async ({
  title,
  text,
  focusDeny,
  input,
  inputLabel,
  confirmButtonText,
  denyButtonText,
  inputValidator
}) => {
  const { isConfirmed, value } = await Swal.fire({
    title,
    text,
    showConfirmButton: true,
    showDenyButton: true,
    allowOutsideClick: false,
    showCloseButton: false,
    icon: 'warning',
    input,
    inputLabel,
    confirmButtonText,
    denyButtonText,
    inputValidator,
    focusDeny
  })

  return { isConfirmed, value }
}

async function getAggregatedFileReport ({ s3Instance, targetDocument }) {
  try {
    const bucket = BUCKET_NAME
    const key = await generateAggregatedFileKey({ targetDocument })

    const output = await s3Instance.readFile({
      bucket,
      key
    })
    return output
  } catch (err) {
    if (err.Code === 'AccessDenied') {
      return undefined
    }
    throw err
  }
}

const openPopup = async ({ targetDocument }) => {
  const link = `${REPORT_BASE_URL}/?documento=${targetDocument}`

  const { isConfirmed } = await Swal.fire({
    title: 'Relatório gerado com sucesso',
    text: 'Clique no botão abaixo para ir para o relatório!',
    showConfirmButton: true,
    showCloseButton: true,
    icon: 'success',
    confirmButtonText: 'Ir para o relatório'
  })

  if (isConfirmed) {
    window.open(link, '_blank')
  }
}

async function writeForm ({ forms, s3Instance, targetDocument }) {
  const bucket = BUCKET_NAME
  const internalScope = await resolveScope()
  const key = `boanota/${internalScope}/applications/relatorio_cadastro/formulario_plataforma/document=${targetDocument}/json`

  const output = await s3Instance.writeFile({
    bucket,
    key,
    data: forms,
    contentType: 'application/json'
  })

  return { bucket, key, versionId: output.VersionId }
}

const getAggregatedFileKey = async ({ targetDocument }) => {
  const internalScope = await resolveScope()
  const key = `boanota/${internalScope}/applications/relatorio_cadastro/resource=aggregate/document=${targetDocument}/json`

  return key
}

const generateAggregatedFileKey = async ({ targetDocument }) => {
  const internalScope = await resolveScope()
  const key = `boanota/${internalScope}/applications/relatorio_cadastro/resource=aggregate/document=${targetDocument}/relatorio.json`

  return key
}

const getCommentField = () => {
  return `all-undefined`
}

const getBackupCommentField = () => {
  return `all-backup-undefined`
}

async function readAggregatedFile ({ s3Instance, targetDocument }) {
  const bucket = BUCKET_NAME
  const key = await getAggregatedFileKey({ targetDocument })

  const data = await s3Instance.readFile({ bucket, key })
  return data
}

async function writeAggregatedFile ({ s3Instance, targetDocument, data }) {
  const bucket = BUCKET_NAME
  const key = await generateAggregatedFileKey({ targetDocument })

  const output = await s3Instance.writeFile({
    bucket,
    key,
    data,
    contentType: 'application/json'
  })
  return { bucket, key, versionId: output.VersionId }
}

export function * changeReportStatusSulBrasil (action) {
  yield put(setFormularioSalvoSulBrasil(false))
}

export function * setStreetViewSulBrasil (action) {
  const { images, geolocation } = action.payload

  const base64Images = yield all(images.map(url => call(fetchBase64, url)))
  yield put(
    setDefaultStreetViewImageSulBrasil({
      geolocation,
      images: base64Images.map(imageData => ({
        content: 'data:image/jpg;base64,' + imageData,
        name: hashObject(imageData)
      }))
    })
  )
}

async function copyAllFiles ({ s3Instance, originalPaths, targetPaths }) {
  const bucket = BUCKET_NAME

  const paths = Object.entries(originalPaths).map(([keyObj, value]) => {
    return { from: `${bucket}/${value.key}`, to: targetPaths[keyObj].key }
  })

  await Promise.allSettled(
    paths.map(({ from, to }) =>
      s3Instance.copyFile({
        bucket,
        sourceKey: from,
        destinationKey: to,
        contentType: 'application/json'
      })
    )
  )
}

export function * listenerFinish (action) {
  const { isCPF } = yield select(selectMinData)

  // if (!isCPF) {
  //   return
  // }

  // ADICIONAR TRATAMENTO DIFERENCIADO AO TERMINAR PROCESSAMENTO PARA PF
}
