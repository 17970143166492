import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { add, format } from 'date-fns'
import { ReactGrid } from '@silevis/reactgrid'
import '@silevis/reactgrid/styles.css'

import { useLogSul } from '../../../../../../utils/hooks'
import { PROGRESS_TYPES } from '../../../../../../lib/progressStatus'
import { selectMinData } from '../../../../../../redux/selectors/dadosBasicos'
import { Loading } from '../../../../../../components'
import { setFaturamentoSulBrasil } from '../../../../../../redux/store/sulbrasil'
import { toast } from 'react-toastify'

const getColumns = () => [
  { columnId: 'date', width: 150 },
  { columnId: 'value', width: 150 }
]

const headerRow = {
  rowId: 'header',
  cells: [
    { type: 'header', text: 'Data' },
    { type: 'header', text: 'Faturamento' }
  ]
}

const frequencias = [
  { text: 'Mensal', value: '1' },
  { text: 'Trimestral', value: '3' },
  { text: 'Semestral', value: '6' },
  { text: 'Anual', value: '12' }
]

const resolveAmount = frequencia => {
  switch (frequencia) {
    case '1':
      return 'meses'
    case '3':
      return 'trimestres'
    case '6':
      return 'semestres'
    case '12':
      return 'anos'
    default:
      return 'indefinido'
  }
}

export default function Faturamento ({ value }) {
  const dispatch = useDispatch()
  const { targetDocument } = useSelector(selectMinData)
  const { status, data: logsulData } = useLogSul()

  const [date, setDate] = useState(new Date())
  const [frequencia, setFrequencia] = useState('1')
  const [efeitosSazonais, setEfeitosSazonais] = useState('1')
  const [quantidade, setQuantidade] = useState('3')

  const [rows, setRows] = useState(value ?? [headerRow])
  const columns = getColumns()

  const hasInvoices =
    (logsulData.faturamento?.data || []).length > 0 &&
    (logsulData.faturamento?.valor || []).length > 0

  const handleGerarPlanilha = () => {
    const nextRows = new Array(parseInt(quantidade)).fill(0).map((_, idx) => {
      return {
        rowId: idx,
        cells: [
          {
            type: 'date',
            date: add(new Date(), { months: parseInt(frequencia) + idx })
          },
          { type: 'number', value: 0 }
        ]
      }
    })

    setRows([headerRow, ...nextRows])
  }

  const handleSalvar = () => {
    const rowsData = rows.filter(row => row.rowId !== 'header')

    dispatch(setFaturamentoSulBrasil(rowsData))
    toast.success('Faturamento parcialmente salvo!')
  }

  if (status === PROGRESS_TYPES.PENDING && targetDocument) {
    return <Loading />
  }

  if (!targetDocument) {
    return null
  }

  const hasTargetDocument = !!targetDocument

  return (
    <>
      {hasInvoices && (
        <div className='row'>
          <div className='col-12'>
            <div className='alert alert-warning' role='alert'>
              <strong>Atenção!</strong>
              <p>
                Já encontramos dados de faturamento para este cliente através do
                LogSul.
              </p>
            </div>
          </div>
        </div>
      )}

      <div className='row'>
        <div className='col-12 col-md-6'>
          <div className='form-group'>
            <label>Data inicial</label>
            <input
              type='date'
              className='form-control'
              onChange={event => setDate(new Date(event.target.value))}
              value={format(date, 'yyyy-MM-dd')}
            />
          </div>
        </div>
        <div className='col-12 col-md-6'>
          <div className='form-group'>
            <label>Frequência</label>
            <select
              className='form-control'
              onChange={event => setFrequencia(event.target.value)}
              value={frequencia ?? null}
            >
              {frequencias.map(item => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.text}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-md-6'>
          <div className='form-group'>
            <label>Efeitos sazonais</label>
            <select
              className='form-control'
              onChange={event => setEfeitosSazonais(event.target.value)}
              value={efeitosSazonais ?? null}
            >
              {new Array(12).fill(0).map((_, idx) => {
                const value = (idx + 1).toString()
                return (
                  <option key={idx.toString()} value={value}>
                    {value}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
        <div className='col-12 col-md-6'>
          <div className='form-group'>
            <label>Quantidade de {resolveAmount(frequencia)} </label>
            <select
              className='form-control'
              onChange={event => setQuantidade(event.target.value)}
              value={quantidade ?? null}
            >
              {new Array(48).fill(0).map((_, idx) => {
                const value = (idx + 1).toString()
                return (
                  <option key={idx.toString()} value={value}>
                    {value}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 text-center'>
          <button
            className='btn btn-primary'
            onClick={handleGerarPlanilha}
            disabled={!hasTargetDocument}
          >
            Gerar planilha
          </button>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-md-12 mt-4'>
          <div className='mt-3 d-flex align-items-center justify-content-center'>
            <ReactGrid
              rows={rows}
              columns={columns}
              onCellsChanged={changes => {
                setRows(prev => {
                  const newRows = prev.map(row => {
                    const selectedRow = changes.find(
                      change => change.rowId === row.rowId
                    )

                    if (!selectedRow) {
                      return row
                    }

                    const columnIndex = getColumns().findIndex(
                      column => column.columnId === selectedRow.columnId
                    )
                    const newCells = [...(row.cells || [])]
                    newCells[columnIndex] = selectedRow.newCell

                    return {
                      ...row,
                      rowId: row.rowId,
                      cells: newCells
                    }
                  })

                  return newRows
                })
              }}
            />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 text-center mt-4'>
          <button
            className='btn btn-primary'
            onClick={handleSalvar}
            disabled={!hasTargetDocument}
          >
            Salvar Faturamento
          </button>
        </div>
      </div>
    </>
  )
}
