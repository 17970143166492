const questionnaires = [
  {
    question:
      'Quadro societário alterado ou empresas constituídas em prazo inferior a 1 ano.',
    id: 'quadroSocietarioAlterado',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'Mais de 70% das operações não são creditadas na sua própria conta corrente.',
    id: 'operacoesNaoCreditadas',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'O quadro societário tem participação de pessoas politicamente expostas - PPE ou a elas relacionadas.',
    id: 'quadroSocietarioPPE',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'Operações que indiquem a realização de negócio entre o cedente e empresa a ele relacionada (intercompany)?',
    id: 'operacoesIndiquemNegocioIntercompany',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  },
  {
    question:
      'Operações cujos títulos demonstrem alteração atípica de volume e/ou frequência dos negócios entre o cedente e o sacado?',
    id: 'operacoesDemonstremAlteracaoAtipicaVolumeFrequenciaNegocios',
    answers: [
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' }
    ]
  }
]

export default questionnaires
