import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { differenceInMonths } from 'date-fns'

import { RiArrowUpSLine, RiArrowDownSLine } from 'react-icons/ri'

import * as styles from './style.module.scss'

import questionnaires from '../../../../../../questionnaires/pld'
import { selectMinData } from '../../../../../../redux/selectors/dadosBasicos'

import { PROGRESS_TYPES } from '../../../../../../lib/progressStatus'

import { useDadosBasicos, usePep } from '../../../../../../utils/hooks'
import { hashObject } from '../../../../../../utils'

import { Loading } from '../../../../../../components'
import { setScorePldSulBrasil } from '../../../../../../redux/store/sulbrasil'

const formatBool = bool => {
  if (typeof bool !== 'boolean') {
    return undefined
  }

  return bool ? 'sim' : 'nao'
}

const computeScore = ({ values, defaultValues }) => {
  const mergedValues = Object.entries(values).map(([key, { value }]) => [
    key,
    value ?? defaultValues[key]?.value
  ])

  const array = mergedValues.sort((a, b) => {
    const aIndex = questionnaires.findIndex(item => item.id === a[0])
    const bIndex = questionnaires.findIndex(item => item.id === b[0])
    return aIndex - bIndex
  })
  const arrayValues = array
    .map(([, value]) => value)
    .filter(value => value !== undefined)

  const first13Questions = arrayValues.slice(0, 13)

  if (arrayValues.length !== questionnaires.length) {
    return 'Informações insuficientes para o cálculo de risco'
  }

  if (first13Questions.includes('sim')) {
    return 'Risco Elevado'
  }

  if (arrayValues.every(i => i === 'nao')) {
    return 'Risco Baixo'
  }

  return 'Informações insuficientes para o cálculo de risco'
}

export default function ScorePld () {
  const { targetDocument, isCPF } = useSelector(selectMinData)
  const [visibleDescriptions, setVisibleDescriptions] = useState({})
  const { data: dadosBasicos, status: statusDadosBasicos } = useDadosBasicos()
  const { data: pep, status: statusPep } = usePep()
  const form = useSelector(state => state.sulbrasil.formulario.scorePld.form)
  const dispatch = useDispatch()

  const values = useMemo(() => {
    return (form || []).reduce((obj, item) => {
      obj[item.id] = { value: item.value, description: item.description }
      return obj
    }, {})
  }, [form])

  const defaultValues = useMemo(() => {
    if (isCPF) {
      return {
        quadroSocietarioAlterado: undefined,
        quadroSocietarioPPE: undefined,
        empresasConstituidas: undefined
      }
    }

    const today = new Date()
    const socios = dadosBasicos?.socios?.receitaFederal || []
    const maxDataEntrada = new Date(
      Math.max.apply(
        null,
        socios.map(socio => new Date(socio.dataEntradaSociedade))
      )
    )
    const dataAberturaEmpresa = new Date(
      dadosBasicos?.dadosBasicos?.datas.dataInicioAtividade || Infinity
    )

    const diffYears = (a, b) => differenceInMonths(a, b) / 12

    const perguntaMinExistencia = formatBool(
      diffYears(today, maxDataEntrada) <= 1 ||
        diffYears(today, dataAberturaEmpresa) <= 1
    )
    const descriptionMinExistencia =
      perguntaMinExistencia === 'sim'
        ? diffYears(today, dataAberturaEmpresa) <= 1
          ? `A empresa foi aberta em ${dataAberturaEmpresa.toLocaleDateString(
              'pt-BR'
            )}`
          : `Houve a entrada de pelo menos um sócio em ${maxDataEntrada.toLocaleDateString(
              'pt-BR'
            )}`
        : ''

    const parentesSociosESocios = socios
      .map(item => ({
        document: item.cnpjCpfSocio,
        name: item.nomeRazaoSocial,
        isParent: false
      }))
      .concat(
        (dadosBasicos?.pessoasFisicasRelacionadas?.parentesSocios || []).map(
          item => ({
            document: item.document,
            name: item.nome,
            isParent: true,
            relationship: item.relationship,
            nomePessoaLigacao: item.nomePessoaLigacao
          })
        )
      )
    const documentoParentesSociosESocios = parentesSociosESocios.map(
      item => item.document
    )
    const nomesParentesSociosESocios = parentesSociosESocios.map(
      item => item.name
    )
    const uniquePeps = Array.from(
      new Map(
        pep
          .filter(
            item =>
              item.isPep &&
              (documentoParentesSociosESocios.includes(item.document) ||
                nomesParentesSociosESocios.includes(item.name))
          )
          .map(item => [`${item.document}-${item.name}`, item])
      ).values()
    ).map(item => ({
      ...item,
      ...(parentesSociosESocios.find(
        socio => socio.document === item.document || socio.name === item.name
      ) ?? {})
    }))
    const perguntaPep = formatBool(uniquePeps.length > 0)
    const descriptionPep =
      perguntaPep === 'sim'
        ? `A empresa possui ${
            uniquePeps.length
          } pessoas politicamente expostas relacionadas:\n${uniquePeps
            .map(item =>
              !item.isParent
                ? `${item.name}`
                : `${item.name ??
                    item.document} ${item.relationship.toLowerCase()} de ${
                    item.nomePessoaLigacao
                  }`
            )
            .join('\n')}`
        : ''

    return {
      quadroSocietarioAlterado: perguntaMinExistencia
        ? {
            value: perguntaMinExistencia,
            description: descriptionMinExistencia
          }
        : undefined,
      quadroSocietarioPPE: perguntaPep
        ? { value: perguntaPep, description: descriptionPep }
        : undefined,
    }
  }, [dadosBasicos, pep, isCPF])

  const onChangeDescription = nextObj => {
    const descriptions = form.reduce((obj, { id, description }) => {
      obj[id] = description
      return obj
    }, {})

    const nextDescriptions = { ...descriptions, ...nextObj }
    const formValues = questionnaires.reduce((obj, { id }) => {
      obj[id] = {
        value: form.find(item => item.id === id)?.value,
        description: nextDescriptions[id] ?? ''
      }
      return obj
    }, {})

    const result = computeScore({ values: formValues, defaultValues })
    dispatch(setScorePldSulBrasil({ form: formValues, result }))
  }

  const onChangeValue = nextObj => {
    const nextValues = { ...values, ...nextObj }

    const formValues = questionnaires.reduce((obj, { id }) => {
      obj[id] = {
        value: nextValues[id]?.value ?? defaultValues[id]?.value,
        description:
          nextValues[id]?.description ?? defaultValues[id]?.description ?? ''
      }
      return obj
    }, {})

    const result = computeScore({ values: formValues, defaultValues })
    dispatch(setScorePldSulBrasil({ form: formValues, result }))
  }

  const result = useMemo(() => {
    return computeScore({ values, defaultValues })
  }, [values, defaultValues])

  if (!targetDocument) {
    return <div />
  }

  if (
    [statusDadosBasicos, statusPep].some(
      status => status === PROGRESS_TYPES.PENDING
    )
  ) {
    return <Loading />
  }

  return (
    <>
      <div>
        {questionnaires.map((questionnaire, questionIndex, array) => {
          const name = questionnaire.id
          const questionKey = hashObject({
            key: 'pld',
            questionnaire,
            questionIndex
          })

          const isDescriptionVisible = visibleDescriptions[name]

          return (
            <div className='my-4' key={questionKey}>
              <div className={styles.question}>
                {questionIndex + 1} - {questionnaire.question}
              </div>
              <div className='d-flex gap-4 mt-2'>
                {questionnaire.answers.map((answer, answerIndex) => {
                  const id = hashObject({
                    questionKey,
                    answer,
                    questionIndex,
                    answerIndex
                  })
                  return (
                    <div className='form-check' key={id}>
                      <input
                        className='form-check-input'
                        type='radio'
                        name={id}
                        id={id}
                        value={answer.value}
                        onChange={event => {
                          if (onChangeValue) {
                            onChangeValue({
                              [name]: {
                                value: event.target.value,
                                description:
                                  values?.[name]?.description ?? undefined
                              }
                            })
                          }
                        }}
                        checked={
                          (values?.[name]?.value ??
                            defaultValues[name]?.value) === answer.value
                        }
                      />
                      <label className='form-check-label' htmlFor={id}>
                        {answer.text}
                      </label>
                    </div>
                  )
                })}
              </div>
              <div className='mt-1'>
                <label
                  htmlFor={`${questionKey}-description`}
                  className={styles.description}
                >
                  {isDescriptionVisible ? 'ocultar descrição' : 'ver descrição'}{' '}
                  <button
                    className='btn btn-sm'
                    onClick={() =>
                      setVisibleDescriptions(prev => ({
                        ...prev,
                        [name]: !isDescriptionVisible
                      }))
                    }
                  >
                    {isDescriptionVisible ? (
                      <RiArrowUpSLine color='#0045c8' />
                    ) : (
                      <RiArrowDownSLine color='#0045c8' />
                    )}
                  </button>
                </label>
                {isDescriptionVisible && (
                  <textarea
                    className='form-control'
                    id={`${questionKey}-description`}
                    rows={3}
                    value={
                      values?.[name]?.description ??
                      defaultValues[name]?.description
                    }
                    onChange={event => {
                      if (onChangeDescription) {
                        onChangeDescription({
                          [name]: event.target.value
                        })
                      }
                    }}
                  />
                )}
              </div>
              <hr
                className={array.length - 1 === questionIndex ? 'mt-5' : ''}
              />
            </div>
          )
        })}
      </div>
      <div>
        <div className='h4 text-center'>{result}</div>
      </div>
    </>
  )
}
