import axios from 'axios'
import { SPREAD_BASE_URL } from '../../config/env'
import { getIdToken } from '../../utils'

export const existsSpread = async ({ cnpj }) => {
  const cleanCnpj = cnpj.replace(/\D/g, '')
  const idToken = await getIdToken()
  const { data } = await axios.get(`${SPREAD_BASE_URL}/tem-spread`, {
    params: { id: cleanCnpj },
    headers: {
      Authorization: `Bearer ${idToken}`
    }
  })

  const output = {
    hasSpread: data.tem_spread,
    hasSpreadConsolidado: data.tem_spread_consolidado
  }

  return output
}
